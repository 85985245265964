import axiosInstance from './AxiosInstance';

export const getLoggedInUserApi = (userData) =>
  axiosInstance.post('auth/admin/login', userData);

export const forgotPasswordUserApi = (userData) =>
  axiosInstance.post('auth/forgot-password-email', userData);

export const verifyOptUserApi = (userData) =>
  axiosInstance.post('auth/forgot-password-code', userData);

export const resetPasswordUserApi = (userData) =>
  axiosInstance.post('auth/forgot-password', userData);

export const getReportedUsersApi = (userData) =>
  axiosInstance.post('user/reported/list', userData);

export const deleteReportedUserApi = (userId) =>
  axiosInstance.delete(`user/delete/${userId}`);

export const clearReportedUserApi = (userId) =>
  axiosInstance.delete(`user/remove/${userId}`);

export const getAllUsersApi = (userData) => axiosInstance.post(`user/list`, userData);

export const activeInactiveUserApi = (userId, postData) =>
  axiosInstance.post(`/user/update/influencer/${userId}`, postData);

  export const userDetailApi = (userId) =>
  axiosInstance.get(`order/history/${userId}`);

  export const updateUserCoinApi = (userData) =>
  axiosInstance.post('order/coin', userData);

  