import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  NavLink,
} from 'reactstrap';

import usePasswordShow from 'hooks/usePasswordShow';
import { fetchLoginUserThunkAction } from 'redux/user/actions';
import { selectUser } from 'redux/user/selectors';

const Login = () => {
  const dispatch = useDispatch();

  let history = useHistory();

  const { isSigning } = useSelector(selectUser);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { passwordShown, togglePasswordVisiblity, type } = usePasswordShow();
  const navigateToHome = () => {
    history.replace('/admin/reportedposts');
  };

  const submitHadler = (e) => {
    e.preventDefault();
    if (password === '' || email === '') {
      toast.error('All fields are required!');
      return;
    }
    dispatch(
      fetchLoginUserThunkAction(
        { email: email, password: password },
        navigateToHome
      )
    );
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form" onSubmit={submitHadler}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    required
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>

                  <Input
                    placeholder="Password"
                    type={type}
                    name="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputGroupAddon
                    addonType="append"
                    style={{ cursor: 'pointer', opacity: '0.5' }}
                    onClick={togglePasswordVisiblity}
                  >
                    <InputGroupText>
                      {passwordShown ? (
                        <img
                          alt="hidepassword"
                          src={
                            require('assets/img/theme/hide-password-icon.png')
                              .default
                          }
                        />
                      ) : (
                        <img
                          alt="showpassword"
                          src={
                            require('assets/img/theme/show-password-icon.png')
                              .default
                          }
                        />
                      )}
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <NavLink
                to="/auth/forgotpassword"
                tag={Link}
                className="nav-link-inner--text"
                style={{ position: 'absolute', right: '2.5rem' }}
              >
                <small>Forgot Password?</small>
              </NavLink>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="submit"
                  disabled={isSigning ? true : false}
                >
                  {isSigning ? 'Signing in...' : 'Sign in'}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
