import { combineReducers } from 'redux';
import userReducer from './user/reducer';
import postReducer from './posts/reducer';
import orderReducer from './orders/reducer';
import commentReducer from './comment/reducer';

const rootReducer = combineReducers({
  post: postReducer,
  user: userReducer,
  order: orderReducer,
  comment: commentReducer,
});

export default rootReducer;
