/*eslint-disable*/
import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Media,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import Swal from "sweetalert2";

import Header from "components/Headers/Header.jsx";
import PostModal from "components/Modals/PostModal";
import Search from "components/Search/Search";

import useViewport from "hooks/useViewport";

import { fetchReportedPostsThunkAction } from "redux/posts/actions";
import { deleteReportedPostsThunkAction } from "redux/posts/actions";
import { selectUserToken } from "redux/user/selectors";
import { selectPosts } from "redux/posts/selectors";
import { setPostSearch } from "redux/posts/actions";
import { getPostMediaURL } from "utils/getMediaUrl";
import "../assets/css/reportedPosts.css";
import { clearReportedPostsThunkAction } from "redux/posts/actions";
import TooltipIcon from "components/TooltipIcon/TooltipIcon";
import ExportButton from "components/ExportButton/ExportButton";
import Loader from "components/Loader/Loader";
import { formattedString } from "utils/getMediaUrl";
import NewPagination from "components/NewPagination/NewPagination";

const ReportedPosts = () => {
  const [posts, setPosts] = React.useState([]);
  const [post, setPost] = React.useState(null);
  const [error, setError] = React.useState(null);

  const [currentPage, setCurrentPage] =
    React.useState(1);
  const [postsPerPage] = React.useState(10);
  const [exportedData, setExportedData] =
    React.useState([]);

  const [open, setOpen] = React.useState(false);

  const { width } = useViewport();

  const toggleModal = () => {
    setOpen((open) => !open);
  };

  const dispatch = useDispatch();

  const { reportedPosts, isLoading, search } =
    useSelector(selectPosts);
  const token = useSelector(selectUserToken);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const onDelete = () => {
    setError(null);
  };

  const onSearchHandler = (keyword) => {
    setCurrentPage(1);
    dispatch(setPostSearch(keyword));
  };

  React.useEffect(() => {
    if (token) {
      dispatch(
        fetchReportedPostsThunkAction(
          search,
          onSuccess,
          onError
        )
      );
    }
  }, [token, search]);

  React.useEffect(() => {
    const pagePosts = reportedPosts.slice(
      (currentPage - 1) * postsPerPage,
      currentPage * postsPerPage
    );
    setPosts(pagePosts);
  }, [
    reportedPosts,
    reportedPosts.length,
    currentPage,
  ]);

  const deletePostHandler = async (postId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(
          await deleteReportedPostsThunkAction(
            search,
            postId,
            onDelete,
            onError
          )
        );
      }
    });
  };

  const clearPostHandler = async (postId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#d33",
      confirmButtonText: "Remove",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(
          await clearReportedPostsThunkAction(
            search,
            postId,
            onDelete,
            onError
          )
        );
      }
    });
  };

  React.useEffect(() => {
    const formatedData = reportedPosts.map(
      (item) => ({
        postid: item?.id,
        description: formattedString(item?.description),
        createdBy:
          item?.created_by_user?.username,
        reportedBy:
          item?.reported_by_user?.username,
          Reason: item?.report_text?.replace('\n', ' '),
      })
    );

    const csvData = formatedData.reduce(
      (acc, item) => {
        const {
          postid,
          description,
          createdBy,
          reportedBy,
          Reason
        } = item;
        acc.push(
          [
            postid,
            description,
            createdBy,
            reportedBy,
            Reason
          ].join(",")
        );
        return acc;
      },
      []
    );

    setExportedData(csvData);
  }, [reportedPosts]);

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card
              className="shadow"
              style={{ minHeight: "50vh" }}
            >
              <CardHeader
                className={
                  width < 768
                    ? "border-0"
                    : "border-0 d-flex justify-content-between"
                }
              >
                <div>
                  <h3 className="mb-2">
                    Reported Posts
                  </h3>
                  {reportedPosts.length > 0 && (
                    <ExportButton
                      fileData={exportedData}
                      fileName="reportedPosts"
                      headers={[
                        "PostId, Description,Created By,Reported By,Reason",
                      ]}
                    >
                      Export Data
                    </ExportButton>
                  )}
                </div>
                <Search
                  onSubmitHandler={
                    onSearchHandler
                  }
                  search={search}
                />
              </CardHeader>
              {isLoading ? (
                <>
                  <>
                    <div className="post_error_loading">
                      <Loader/>
                    </div>
                  </>
                </>
              ) : (
                <>
                  {error ? (
                    <>
                      <>
                        <div className="post_error_loading">
                          {error}
                        </div>
                      </>
                    </>
                  ) : (
                    <>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">
                              Post Id
                            </th>
                            <th scope="col">
                              Description
                            </th>
                            <th scope="col">
                              Images
                            </th>
                            <th scope="col">
                              Created By
                            </th>
                            <th scope="col">
                              Reported By
                            </th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {posts.length > 0 &&
                            posts.map((post) => {
                              const {
                                media_url1,
                                media_url2,
                              } =
                                getPostMediaURL(
                                  post
                                );

                              return (
                                <tr
                                  key={
                                    post?.id +
                                    post
                                      ?.reported_by_user
                                      ?.username
                                  }
                                >
                                  <th scope="row">
                                    <Media
                                      className="align-items-center"
                                      style={{
                                        cursor:
                                          "pointer",
                                      }}
                                      onClick={() => {
                                        setPost(
                                          post
                                        );
                                        setOpen(
                                          true
                                        );
                                      }}
                                    >
                                      <span className="mb-0 text-sm">
                                        {post?.id}
                                      </span>
                                    </Media>
                                  </th>
                                  <td>
                                    {post
                                      ?.description
                                      .length > 20
                                      ? post?.description.slice(
                                          0,
                                          20
                                        )
                                      : post?.description}
                                    {post
                                      .description
                                      .length > 20
                                      ? "..."
                                      : ""}
                                  </td>
                                  <td
                                    style={{
                                      display:
                                        "flex",
                                    }}
                                  >
                                    <Media
                                      className="align-items-center"
                                      onClick={() => {
                                        setPost(
                                          post
                                        );
                                        setOpen(
                                          true
                                        );
                                      }}
                                    >
                                      <a
                                        className="avatar rounded-circle mr-3"
                                        href="#"
                                        onClick={(
                                          e
                                        ) =>
                                          e.preventDefault()
                                        }
                                        style={{
                                          background:
                                            media_url1.background ||
                                            "",
                                        }}
                                      >
                                        {typeof media_url1 ===
                                        "string" ? (
                                          <img
                                            alt="..."
                                            className="post_image"
                                            src={`${media_url1}`}
                                          />
                                        ) : (
                                          <div
                                            className="post_image_text"
                                            style={{
                                              color:
                                                media_url1?.text_color ||
                                                "black",
                                            }}
                                          >
                                            {
                                              media_url1?.text
                                            }
                                          </div>
                                        )}
                                      </a>
                                    </Media>
                                    <Media
                                      className="align-items-center"
                                      onClick={() => {
                                        setPost(
                                          post
                                        );
                                        setOpen(
                                          true
                                        );
                                      }}
                                    >
                                      <a
                                        className="avatar rounded-circle mr-3"
                                        href="#pablo"
                                        onClick={(
                                          e
                                        ) =>
                                          e.preventDefault()
                                        }
                                        style={{
                                          background:
                                            media_url2.background ||
                                            "",
                                        }}
                                      >
                                        {typeof media_url2 ===
                                        "string" ? (
                                          <img
                                            alt="..."
                                            className="post_image"
                                            src={`${media_url2}`}
                                          />
                                        ) : (
                                          <div
                                            className="post_image_text"
                                            style={{
                                              color:
                                                media_url2?.text_color ||
                                                "black",
                                            }}
                                          >
                                            {
                                              media_url2?.text
                                            }
                                          </div>
                                        )}
                                      </a>
                                    </Media>
                                  </td>
                                  <td>
                                    {
                                      post
                                        ?.created_by_user
                                        ?.username
                                    }
                                    {post
                                      ?.created_by_user
                                      ?.is_influencer ===
                                    1 ? (
                                      <img
                                        style={{
                                          width: 20,
                                          height: 20,
                                          marginLeft: 5,
                                        }}
                                        alt="verified"
                                        src={
                                          require("../assets/img/theme/verified.png")
                                            .default
                                        }
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    {
                                      post
                                        ?.reported_by_user
                                        ?.username
                                    }
                                    {post
                                      ?.reported_by_user
                                      ?.is_influencer ===
                                    1 ? (
                                      <img
                                        style={{
                                          width: 20,
                                          height: 20,
                                          marginLeft: 5,
                                        }}
                                        alt="verified"
                                        src={
                                          require("../assets/img/theme/verified.png")
                                            .default
                                        }
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td className="">
                                    <TooltipIcon
                                      target={
                                        "deletepost" +
                                        post?.id
                                      }
                                      label="Delete"
                                      iconClass="fas fa-trash"
                                      onclickHandler={() =>
                                        deletePostHandler(
                                          post?.id
                                        )
                                      }
                                      style={{
                                        cursor:
                                          "pointer",
                                      }}
                                    />

                                    <TooltipIcon
                                      target={
                                        "clearpost" +
                                        post?.id
                                      }
                                      label="Clear"
                                      iconClass="fas fa-times"
                                      onclickHandler={() =>
                                        clearPostHandler(
                                          post?.id
                                        )
                                      }
                                      style={{
                                        cursor:
                                          "pointer",
                                        marginLeft:
                                          "1rem",
                                        fontSize:
                                          "0.9rem",
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                      {!error &&
                        posts.length === 0 && (
                          <>
                            <div className="no_posts">
                              No any reported
                              posts.
                            </div>
                          </>
                        )}
                      {posts && posts.length > 0 && (
                        <CardFooter className="py-4">
                          <nav
                            aria-label="..."
                            className="post_footer"
                          >
                            <NewPagination
                              totalRecords={reportedPosts.length}
                              setCurrentPage={setCurrentPage}
                              pageLimit={postsPerPage}
                              currentPage={currentPage}
                              pageNeighbours={1}
                              
                            />
                          </nav>
                        </CardFooter>
                      )}
                    </>
                  )}
                </>
              )}
            </Card>
          </div>
        </Row>
        {post && open && (
          <PostModal
            toggleModal={toggleModal}
            isOpen={open}
            post={post}
          />
        )}
      </Container>
    </>
  );
};

export default ReportedPosts;
