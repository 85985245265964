import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  NavLink,
} from 'reactstrap';

import { forgetPasswordEmailThunkAction } from 'redux/user/actions';

const ForgotPassword = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [email, setEmail] = useState('');

  const navigateToHome = () => {
    history.push('/auth/verifyotp');
  };

  const submitHadler = (e) => {
    if (email === '') {
      toast.error('Please enter your email.');
      return;
    }
    e.preventDefault();
    dispatch(forgetPasswordEmailThunkAction(email, navigateToHome));
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form" onSubmit={submitHadler}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    required
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
              </div>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Submit
                </Button>
                <NavLink to="/auth/login" tag={Link}>
                  <small className="nav-link-inner--text">Sign in?</small>
                </NavLink>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ForgotPassword;
