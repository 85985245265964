import GoBackButton from 'components/GobackButton/GoBackButton';
import moment from 'moment';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Row,
  Table,
} from 'reactstrap';
import Header from 'components/Headers/Header';
import defaultProfileImage from '../assets/img/brand/Circleprofile.svg';
import { userDetailThunkAction } from 'redux/user/actions';
import { selectUser } from 'redux/user/selectors';
import Loading from 'components/Loading/Loading';
import { updateUserCoinThunkAction } from 'redux/user/actions';
import toast from 'react-hot-toast';

function UserDetails() {
  const [userData, setUserData] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);
  const [updatedCoin, setUpdatedCoin] = React.useState('');

  let { id } = useParams();
  const dispatch = useDispatch();

  const { selectedUser, isLoading, error } = useSelector(selectUser);

  React.useEffect(() => {
    if (id) {
      dispatch(userDetailThunkAction(id));
    }
  }, [id]);

  React.useEffect(() => {
    if (selectedUser) {
      setUserData(selectedUser);
    }
  }, [selectedUser]);

  const onSuccess = () => {
    dispatch(userDetailThunkAction(id));
    setUpdatedCoin('');
  };

  const onError = () => {
    setUpdatedCoin('');
  };

  const onClear = () => {
    setIsEdit(false);
    setUpdatedCoin('');
  };

  const updateCoinsHandler = (e) => {
    e.preventDefault();

    if (+updatedCoin === 0) {
      toast.error('Please add coins.');
      return;
    }

    let reqBody = {
      userId: userData?.user?.id,
      coinsCount: +updatedCoin,
    };
    dispatch(updateUserCoinThunkAction(reqBody, onSuccess, onError));
    setIsEdit(false);
  };

  const onChangeHandleCoinValue = (operation) => {
    if (operation === 'plus' && updatedCoin < 9999990) {
      setUpdatedCoin((coin) => +coin + 10);
    } else if (operation === 'minus') {
      if (
        !(
          userData?.user?.coins - Math.abs(+updatedCoin - 10) < 0 &&
          +updatedCoin - 10 <= 0
        )
      ) {
        setUpdatedCoin((coin) => +coin - 10);
      }
    }
  };

  const onChangeHandler = (e) => {
    if (
      !(
        userData?.user?.coins - Math.abs(e.target.value) < 0 &&
        +e.target.value <= 0
      )
    ) {
      if (Number(e.target.value) > 0 && +e.target.value <= 9999999) {
        setUpdatedCoin(e.target.value);
      } else if (Number(e.target.value) <= 0) {
        setUpdatedCoin(e.target.value);
      }
    }
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1 " xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="10">
                    <h2 className="mb-0">User Details</h2>
                  </Col>

                  <Col className="text-right">
                    <GoBackButton />
                  </Col>
                </Row>
              </CardHeader>
              {isLoading ? (
                <React.Fragment>
                  <div className="post_error_loading mb-5">
                    <Loading />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {userData?.user ? (
                    <CardBody className="bg-white">
                      <Row className="mb-4 d-flex flex-wrap-reverse align-content-center">
                        <Col lg={8} sm={12}>
                          <div className="pl-lg-4">
                            <Table className="table-borderless user_details_table">
                              <tbody>
                                <tr>
                                  <td>Username</td>
                                  <td>{userData?.user?.username}</td>
                                </tr>
                                <tr>
                                  <td>Full Name</td>
                                  <td>{userData?.user?.full_name}</td>
                                </tr>
                                <tr>
                                  <td>Email</td>
                                  <td>{userData?.user?.email}</td>
                                </tr>
                                <tr>
                                  <td>Private</td>
                                  <td>
                                    {userData?.user?.is_private ? 'yes' : 'No'}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Influencer</td>
                                  <td>
                                    {userData?.user?.is_influencer
                                      ? 'yes'
                                      : 'No'}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Admin</td>
                                  <td>
                                    {userData?.user?.is_admin ? 'yes' : 'No'}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Coins</td>
                                  <td>
                                    {isEdit ? (
                                      <>
                                        <Form
                                          role="form"
                                          onSubmit={updateCoinsHandler}
                                        >
                                          <FormGroup>
                                            <Row
                                              className="d-flex flex-wrap align-items-center"
                                              style={{ gap: '0.7rem' }}
                                            >
                                              <Col
                                                sm="4"
                                                lg="5"
                                                className="align-items-center d-flex"
                                              >
                                                <span className="pr-3">
                                                  {userData?.user?.coins}
                                                </span>

                                                <Button
                                                  color="secondary"
                                                  role="button"
                                                  type="button"
                                                  disabled={
                                                    userData?.user?.coins -
                                                      Math.abs(+updatedCoin) <=
                                                      0 && +updatedCoin <= 0
                                                  }
                                                  aria-pressed={true}
                                                  onClick={() =>
                                                    onChangeHandleCoinValue(
                                                      'minus'
                                                    )
                                                  }
                                                  className="update_coin_buttons"
                                                >
                                                  <i className="fas fa-minus"></i>
                                                </Button>

                                                <InputGroup className="input-group-alternative">
                                                  <Input
                                                    placeholder="Add Coins"
                                                    style={{
                                                      width: '3rem',
                                                      textAlign: 'center',
                                                      padding: '0.3rem 0',
                                                    }}
                                                    type="number"
                                                    required
                                                    name="accessCode"
                                                    value={updatedCoin}
                                                    onChange={onChangeHandler}
                                                    onWheel={(e) =>
                                                      e.target.blur()
                                                    }
                                                  />
                                                </InputGroup>

                                                <Button
                                                  color="secondary"
                                                  role="button"
                                                  type="button"
                                                  aria-pressed={true}
                                                  onClick={() =>
                                                    onChangeHandleCoinValue(
                                                      'plus'
                                                    )
                                                  }
                                                  className="update_coin_buttons"
                                                >
                                                  <i className="fas fa-plus"></i>
                                                </Button>
                                              </Col>
                                              <Col>
                                                <Button
                                                  color="primary"
                                                  outline
                                                  type="submit"
                                                  size="md"
                                                >
                                                  Update
                                                </Button>
                                                <Button
                                                  outline
                                                  size="md"
                                                  color="warning"
                                                  style={{
                                                    marginRight: '1rem',
                                                  }}
                                                  onClick={onClear}
                                                >
                                                  Cancel
                                                </Button>
                                              </Col>
                                            </Row>
                                          </FormGroup>
                                        </Form>
                                      </>
                                    ) : (
                                      <>
                                        <span>{userData?.user?.coins}</span>
                                        <i
                                          className="fas fa-edit cursor-pointer ml-5"
                                          onClick={() => setIsEdit(true)}
                                        ></i>
                                      </>
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Registered At</td>
                                  <td>
                                    {moment(userData?.user?.created_at).format(
                                      'MM/DD/YYYY [at] h:mm A'
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                        <Col
                          lg={4}
                          sm={12}
                          className="d-flex pr-md-5 image-container"
                        >
                          <div className="profile-image-container">
                            <img
                              alt={userData?.user?.username}
                              className=""
                              src={
                                userData?.user?.profile_image ||
                                defaultProfileImage
                              }
                            />
                          </div>
                        </Col>
                      </Row>

                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Order Id</th>
                            <th scope="col">Coins</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Plan Id</th>
                            <th scope="col">Transaction Id</th>
                            <th scope="col">Purchased At</th>
                          </tr>
                        </thead>

                        <tbody>
                          {userData?.orderData?.length
                            ? userData?.orderData.map((order, index) => {
                                return (
                                  <tr key={order?.id}>
                                    <td>{order?.id}</td>
                                    <td>{order?.coins}</td>
                                    <td>{order?.amount}</td>
                                    <td>{order?.plan_id}</td>
                                    <td>{order?.transaction_id}</td>
                                    <td>
                                      {moment(order?.created_at).format(
                                        'MM/DD/YYYY [at] h:mm A'
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            : null}
                        </tbody>
                      </Table>

                      {userData?.orderData?.length === 0 && (
                        <div className="no_posts">No orders yet!</div>
                      )}
                    </CardBody>
                  ) : (
                    <div className="no_posts">User not found.</div>
                  )}
                </React.Fragment>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default UserDetails;
