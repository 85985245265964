import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
} from 'reactstrap';

import Header from 'components/Headers/Header';

import usePasswordShow from 'hooks/usePasswordShow';
import useViewport from 'hooks/useViewport';

import { changePasswordThunkAction } from 'redux/user/actions';
import { loggingOutUserThunkAction } from 'redux/user/actions';
import { selectLoggedInUser } from 'redux/user/selectors';

const ResetPasswordAdmin = () => {
  const dispatch = useDispatch();

  const { width } = useViewport();

  const state = useSelector(selectLoggedInUser);

  let history = useHistory();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const {
    passwordShown: passwordShown1,
    togglePasswordVisiblity: togglePasswordVisiblity1,
    type: type1,
  } = usePasswordShow();
  const {
    passwordShown: passwordShown2,
    togglePasswordVisiblity: togglePasswordVisiblity2,
    type: type2,
  } = usePasswordShow();

  const navigateToHome = () => {
    history.replace('/auth/login');
  };

  const logoutHandler = () => {
    dispatch(loggingOutUserThunkAction(navigateToHome));
  };

  const submitHadler = (e) => {
    e.preventDefault();
    if (password === '' || confirmPassword === '') {
      toast.error('Please fill all the fields.');
      return;
    }
    if (password.trim() === '' || confirmPassword.trim() === '') {
      toast.error('white spaces are not allowed.');
      return;
    }

    if(password.includes(' ') || confirmPassword.includes(' ')) {
      toast.error('White spaces are not allowed.');
      return;
    }

    if (password !== confirmPassword) {
      toast.error('Password and Confirm Password does not match.');
      return;
    }
    dispatch(
      changePasswordThunkAction(
        { email: state.email, password: password },
        logoutHandler
      )
    );
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div
            className="col"
            style={
              width < 768
                ? {}
                : {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }
            }
          >
            <Card
              style={width < 850 ? { width: '100%' } : { width: '60%' }}
              className="bg-secondary shadow border-0"
            >
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form" onSubmit={submitHadler}>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type={type1}
                        required
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <InputGroupAddon
                        addonType="append"
                        style={{ cursor: 'pointer', opacity: '0.5' }}
                        onClick={togglePasswordVisiblity1}
                      >
                        <InputGroupText>
                          {passwordShown1 ? (
                            <img
                              alt="hidepassword"
                              src={
                                require('assets/img/theme/hide-password-icon.png')
                                  .default
                              }
                            />
                          ) : (
                            <img
                              alt="showpassword"
                              src={
                                require('assets/img/theme/show-password-icon.png')
                                  .default
                              }
                            />
                          )}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Confirm Password"
                        type={type2}
                        required
                        name="confirmpassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <InputGroupAddon
                        addonType="append"
                        style={{ cursor: 'pointer', opacity: '0.5' }}
                        onClick={togglePasswordVisiblity2}
                      >
                        <InputGroupText>
                          {passwordShown2 ? (
                            <img
                              alt="hidepassword"
                              src={
                                require('assets/img/theme/hide-password-icon.png')
                                  .default
                              }
                            />
                          ) : (
                            <img
                              alt="showpassword"
                              src={
                                require('assets/img/theme/show-password-icon.png')
                                  .default
                              }
                            />
                          )}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                  </div>
                  <div className="text-center">
                    <Button className="my-4" color="primary" type="submit">
                      Change Password
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ResetPasswordAdmin;
