import toast from 'react-hot-toast';
import {
  getReportedPostsApi,
  deleteReportedPostApi,
  clearReportedPostApi,
} from '../../services/PostServices';
import {
  DELETE_REPORTED_POST,
  FETCH_REPORTED_POSTS_FAILURE,
  FETCH_REPORTED_POSTS_REQUEST,
  FETCH_REPORTED_POSTS_SUCCESS,
  RESET_POSTS,
  SET_POST_SEARCH,
} from './types';

export const fetchReportedPostsRequest = () => {
  return {
    type: FETCH_REPORTED_POSTS_REQUEST,
  };
};

export const fetchReportedPostsSuccess = (posts) => {
  return {
    type: FETCH_REPORTED_POSTS_SUCCESS,
    payload: posts,
  };
};

export const fetchReportedPostsFailure = (error) => {
  return {
    type: FETCH_REPORTED_POSTS_FAILURE,
    payload: error,
  };
};

export const deleteReportedPost = (user) => {
  return {
    type: DELETE_REPORTED_POST,
    payload: user,
  };
};

export const setPostSearch = (search) => {
  return {
    type: SET_POST_SEARCH,
    payload: search,
  };
};

export const resetPosts = () => {
  return {
    type: RESET_POSTS,
  };
};

export const fetchReportedPostsThunkAction = (search, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchReportedPostsRequest());
      const { data } = await getReportedPostsApi({ keyword: search });

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchReportedPostsSuccess(data.data.reportedPost));
      onSuccess();
    } catch (error) {
      dispatch(fetchReportedPostsFailure(error.message));
      onError(error.message);
    }
  };
};

export const deleteReportedPostsThunkAction = async (
  search = '',
  id,
  onDelete,
  onError
) => {
  return async (dispatch) => {
    try {
      const promise = deleteReportedPostApi(id);

      toast.promise(promise, {
        loading: 'Loading...',
        success: 'Post deleted successfully.',
      });

      const response = await promise;

      if (response.data.success !== true) {
        throw new Error(response.data.message);
      }

      dispatch(fetchReportedPostsRequest());
      const { data } = await getReportedPostsApi({ keyword: search });
      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchReportedPostsSuccess(data.data.reportedPost));

      onDelete();
    } catch (error) {
      dispatch(fetchReportedPostsFailure(error.message));
      onError(error.message);
    }
  };
};

export const clearReportedPostsThunkAction = async (
  search = '',
  id,
  onDelete,
  onError
) => {
  return async (dispatch) => {
    try {
      const promise = clearReportedPostApi(id);

      toast.promise(promise, {
        loading: 'Loading...',
        success: 'Post Removed successfully.',
        error: 'Something went wrong.',
      });

      const response = await promise;

      if (response.data.success !== true) {
        throw new Error(response.data.message);
      }

      dispatch(fetchReportedPostsRequest());
      const { data } = await getReportedPostsApi({ keyword: search });
      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchReportedPostsSuccess(data.data.reportedPost));

      onDelete();
    } catch (error) {
      dispatch(fetchReportedPostsFailure(error.message));
      onError(error.message);
    }
  };
};
