/*eslint-disable*/
import { useState } from 'react';
import { NavLink as NavLinkRRD, Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';

import { loggingOutUserThunkAction } from '../../redux/user/actions';
import { selectLoggedInUser } from 'redux/user/selectors';
import useViewport from 'hooks/useViewport';

const Sidebar = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();

  const user = useSelector(selectLoggedInUser);

  const navigateToHome = () => {
    history.replace('/auth/login');
  };

  const logoutHandler = () => {
    dispatch(loggingOutUserThunkAction(navigateToHome));
  };

  const navigateToResetPasswordHandler = () => {
    history.push('/admin/resetpassword');
  };

  const [collapseOpen, setCollapseOpen] = useState();

  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };

  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
            activeClassName="active"
          >
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: '_blank',
    };
  }

  const { width } = useViewport();

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {logo ? (
          <NavLink
            to="/admin/reportedposts"
            tag={Link}
            style={{ padding: '0' }}
          >
            <img
              alt={logo.imgAlt}
              style={
                width > 768
                  ? {
                      width: '40%',
                      height: '40%',
                      margin: 'auto',
                      display: 'flex ',
                    }
                  : {
                      width: '40%',
                      height: '40%',
                      margin: 'auto',
                      display: 'flex',
                    }
              }
              src={logo.imgSrc}
            />
          </NavLink>
        ) : null}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img
                    alt="..."
                    src={
                      require('../../assets/img/theme/admin-logo.png').default
                    }
                  />
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">
                  Welcome!, {user?.full_name}
                </h6>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={navigateToResetPasswordHandler}>
                <img
                  width="20px"
                  alt="logout"
                  style={{ marginRight: '1rem' }}
                  height="20px"
                  src={
                    require('../../assets/img/theme/change-password.png')
                      .default
                  }
                />
                <span>Change Password</span>
              </DropdownItem>
              <DropdownItem onClick={logoutHandler}>
                <img
                  width="20px"
                  alt="logout"
                  style={{ marginRight: '1rem' }}
                  height="20px"
                  src={require('../../assets/img/theme/sign-out.png').default}
                />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        <Collapse navbar isOpen={collapseOpen}>
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar>{createLinks(routes)}</Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
