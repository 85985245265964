import {
  DELETE_REPORTED_USER,
  FETCH_ALL_USERS_FAILURE,
  FETCH_ALL_USERS_REQUEST,
  FETCH_ALL_USERS_SUCCESS,
  FETCH_REPORTED_USERS_FAILURE,
  FETCH_REPORTED_USERS_REQUEST,
  FETCH_REPORTED_USERS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  FETCH_USER_DETAILS_REQUEST,
  FETCH_USER_DETAILS_SUCCESS,
  REFRESH_STATE,
  RESET_FORGET_PASSWORD_USER_EMAIL,
  RESET_IS_SIGNING,
  SET_FORGET_PASSWORD_USER_EMAIL,
  SET_IS_SIGNING,
  SET_LOGGEDIN_USER,
  SET_LOGGEDOUT_USER,
  SET_USER_SEARCH,
} from './types';

import {
  activeInactiveUserApi,
  clearReportedUserApi,
  deleteReportedUserApi,
  forgotPasswordUserApi,
  getAllUsersApi,
  getLoggedInUserApi,
  getReportedUsersApi,
  resetPasswordUserApi,
  updateUserCoinApi,
  userDetailApi,
  verifyOptUserApi,
} from '../../services/UserServices';
import toast from 'react-hot-toast';
import { resetPosts } from 'redux/posts/actions';

export const fetchReportedUsersRequest = () => {
  return {
    type: FETCH_REPORTED_USERS_REQUEST,
  };
};

export const fetchReportedUsersSuccess = (users) => {
  return {
    type: FETCH_REPORTED_USERS_SUCCESS,
    payload: users,
  };
};

export const fetchReportedUsersFailure = (error) => {
  return {
    type: FETCH_REPORTED_USERS_FAILURE,
    payload: error,
  };
};

export const fetchAllUsersRequest = () => {
  return {
    type: FETCH_ALL_USERS_REQUEST,
  };
};

export const fetchAllUsersSuccess = (users) => {
  return {
    type: FETCH_ALL_USERS_SUCCESS,
    payload: users,
  };
};

export const fetchAllUsersFailure = (error) => {
  return {
    type: FETCH_ALL_USERS_FAILURE,
    payload: error,
  };
};

export const setLoggedInUser = ({ user, token }) => {
  return {
    type: SET_LOGGEDIN_USER,
    payload: { user, token },
  };
};

export const setLoggedOutUser = () => {
  return {
    type: SET_LOGGEDOUT_USER,
  };
};

export const deleteReportedUser = (user) => {
  return {
    type: DELETE_REPORTED_USER,
    payload: user,
  };
};

export const refreshState = ({ token, user }) => ({
  type: REFRESH_STATE,
  payload: { token, user },
});

export const setForgetpasswordUserEmail = (email) => {
  return {
    type: SET_FORGET_PASSWORD_USER_EMAIL,
    payload: email,
  };
};

export const resetForgetpasswordUserEmail = (email) => {
  return {
    type: RESET_FORGET_PASSWORD_USER_EMAIL,
  };
};

export const setUserSearch = (search) => {
  return {
    type: SET_USER_SEARCH,
    payload: search,
  };
};

export const setIsSigning = () => {
  return {
    type: SET_IS_SIGNING,
  };
};

export const resetIsSigning = () => {
  return {
    type: RESET_IS_SIGNING,
  };
};

export const fetchUserDetailRequest = () => {
  return {
    type: FETCH_USER_DETAILS_REQUEST,
  };
};
export const fetchUserDetailSuccess = (users) => {
  return {
    type: FETCH_USER_DETAILS_SUCCESS,
    payload: users,
  };
};

export const fetchUserDetailfailure = (error) => {
  return {
    type: FETCH_USER_DETAILS_FAILURE,
    payload: error,
  };
};

export const fetchLoginUserThunkAction = ({ email, password }, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(setIsSigning());
      const { data } = await getLoggedInUserApi({ username: email, password });

      if (data.success !== true) {
        throw new Error(data.error);
      }
      toast.success('Logged in successfully.');
      localStorage.setItem('token', data.data.token);
      localStorage.setItem('user', JSON.stringify(data.data.user));
      dispatch(
        setLoggedInUser({
          token: data.data.token,
          user: data.data.user,
        })
      );
      onSuccess();
    } catch (error) {
      dispatch(resetIsSigning());
      toast.error(error.message);
    }
  };
};

export const loggingOutUserThunkAction = (onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(setLoggedOutUser());
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      dispatch(resetPosts());
      toast.success('Logged out successfully.');
      onSuccess();
    } catch (error) {}
  };
};

export const forgetPasswordEmailThunkAction = (email, onSuccess) => {
  return async (dispatch) => {
    try {
      const { data } = await forgotPasswordUserApi({
        email: email,
      });
      if (data.success !== true) {
        throw new Error(data.message);
      }
      toast.success('Please varify the OTP send to your email.');
      dispatch(setForgetpasswordUserEmail(email));
      onSuccess();
    } catch (error) {
      toast.error(error.message);
    }
  };
};

export const verifyOptThunkAction = ({ code, email }, onSuccess) => {
  return async (dispatch) => {
    try {
      const { data } = await verifyOptUserApi({ code, email });

      if (data.success !== true) {
        throw new Error(data.message);
      }
      toast.success('OTP verified successfully.');
      onSuccess();
    } catch (error) {
      toast.error(error.message);
    }
  };
};

export const resetPasswordThunkAction = ({ password, email }, onSuccess) => {
  return async (dispatch) => {
    try {
      const { data } = await resetPasswordUserApi({ email, password });

      if (data.success !== true) {
        throw new Error(data.message);
      }
      toast.success('Your password has been reset successfully.');
      dispatch(resetForgetpasswordUserEmail());
      onSuccess();
    } catch (error) {
      toast.error(error.message);
    }
  };
};

export const changePasswordThunkAction = ({ password, email }, onSuccess) => {
  return async (dispatch) => {
    try {
      const { data } = await resetPasswordUserApi({ email, password });
      toast.success('Your password has been changed successfully.');
      localStorage.removeItem('token');
      localStorage.removeItem('user');

      if (data.success !== true) {
        throw new Error(data.message);
      }

      dispatch(resetForgetpasswordUserEmail());
      onSuccess();
    } catch (error) {
      toast.error(error.message);
    }
  };
};

export const fetchReportedUsersThunkAction = (search, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchReportedUsersRequest());
      const { data } = await getReportedUsersApi({ keyword: search });

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchReportedUsersSuccess(data.data.reportedUserData));
      onSuccess();
    } catch (error) {
      dispatch(fetchReportedUsersFailure(error.message));
      onError(error.message);
    }
  };
};

export const fetctAllUsersThunkAction = (
  search,
  filterBy,
  onSuccess,
  onError
) => {
  return async (dispatch) => {
    try {
      dispatch(fetchAllUsersRequest());
      const { data } = await getAllUsersApi({
        keyword: search,
        filter: filterBy,
      });

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchAllUsersSuccess(data.data.allUsersData));
      onSuccess();
    } catch (error) {
      dispatch(fetchAllUsersFailure(error.message));
      onError(error.message);
    }
  };
};

export const activateInactiveUserThunkAction = async (
  id,
  postData,
  onSuccess,
  onError
) => {
  return async (dispatch) => {
    try {
      const promise = activeInactiveUserApi(id, postData);

      toast.promise(promise, {
        loading: 'Loading...',
        success: 'User updated successfully.',
        error: 'Internal server Error.',
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data.message);
      }
      onSuccess();
    } catch (error) {
      onError(error?.response?.data?.message);
    }
  };
};

export const deleteReportedUsersThunkAction = async (
  search,
  id,
  onDelete,
  onError
) => {
  return async (dispatch) => {
    try {
      const promise = deleteReportedUserApi(id);

      toast.promise(promise, {
        loading: 'Loading...',
        success: 'User deleted successfully.',
      });

      const response = await promise;

      if (response.data.success !== true) {
        throw new Error(response.data.message);
      }

      dispatch(fetchReportedUsersRequest());
      const { data } = await getReportedUsersApi({ keyword: search });
      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchReportedUsersSuccess(data.data.reportedUserData));

      onDelete();
    } catch (error) {
      dispatch(fetchReportedUsersFailure(error.message));
      onError(error.message);
    }
  };
};

export const clearReportedUsersThunkAction = async (
  search,
  id,
  onDelete,
  onError
) => {
  return async (dispatch) => {
    try {
      const promise = clearReportedUserApi(id);

      toast.promise(promise, {
        loading: 'Loading...',
        success: 'User Removed successfully.',
      });

      const response = await promise;

      if (response.data.success !== true) {
        throw new Error(response.data.message);
      }

      dispatch(fetchReportedUsersRequest());
      const { data } = await getReportedUsersApi({ keyword: search });
      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchReportedUsersSuccess(data.data.reportedUserData));

      onDelete();
    } catch (error) {
      dispatch(fetchReportedUsersFailure(error.message));
      onError(error.message);
    }
  };
};

export const userDetailThunkAction = (userId) => {
  return async (dispatch) => {
    try {
      dispatch(fetchUserDetailRequest());
      const { data } = await userDetailApi(userId);
      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchUserDetailSuccess(data.data));
    } catch (error) {
      fetchUserDetailfailure(error?.response?.data?.message || error?.message);
      toast.error(error.response?.data?.message || error.message);
    }
  };
};

export const updateUserCoinThunkAction = (postData, onSuccess, onError) => {
  return async (dispatch) => {
    let toastId;
    try {
      toast.loading('Loading...');
      const { data } = await updateUserCoinApi(postData);
      if (data.success !== true) {
        throw new Error(data.message);
      }
      toast.dismiss(toastId);
      toast.success('Coins updated successfully.');
      onSuccess();
    } catch (error) {
      toast.dismiss(toastId);
      onError();
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};
