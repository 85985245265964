import { getOrderHistoryApi } from 'services/OrderServices';

import {
  FETCH_ORDERS_FAILURE,
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_SUCCESS,
} from './types';

export const fetchOrderHistoryRequest = () => {
  return {
    type: FETCH_ORDERS_REQUEST,
  };
};

export const fetchOrderHistorySuccess = (orders) => {
  return {
    type: FETCH_ORDERS_SUCCESS,
    payload: orders,
  };
};

export const fetchOrderHistoryFailure = (error) => {
  return {
    type: FETCH_ORDERS_FAILURE,
    payload: error,
  };
};

export const fetchOrderHistoryThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchOrderHistoryRequest());
      const { data } = await getOrderHistoryApi(params);

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchOrderHistorySuccess(data.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchOrderHistoryFailure(error?.message));
      onError(error?.message);
    }
  };
};
