import AllUsers from 'pages/AllUsers';
import ReportedPosts from 'pages/ReportedPosts';
import ReportedUsers from 'pages/ReportedUsers';
import ReportedComments from 'pages/ReportedComments';
import OrderHistory from 'pages/OrderHistory';

var routes = [
  {
    path: '/reportedusers',
    name: 'Reported Users',
    icon: 'ni ni-circle-08 text-blue',
    component: ReportedUsers,
    layout: '/admin',
  },
  {
    path: '/reportedposts',
    name: 'Reported Posts',
    icon: 'ni ni-bullet-list-67 text-blue',
    component: ReportedPosts,
    layout: '/admin',
  },
  {
    path: '/reportedcomments',
    name: 'Reported Comments',
    icon: 'ni ni-favourite-28 text-blue',
    component: ReportedComments,
    layout: '/admin',
  },
  {
    path: '/users',
    name: 'All Users',
    icon: 'ni ni-single-02 text-blue',
    component: AllUsers,
    layout: '/admin',
  },
  {
    path: '/orderHistory',
    name: 'Order History',
    icon: 'ni ni-bag-17 text-blue',
    component: OrderHistory,
    layout: '/admin',
  },
];
export default routes;
