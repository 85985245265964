import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

class NewPagination extends Component {
  constructor(props) {
    super(props);

    const {
      totalRecords = null,
      pageLimit = 30,
      pageNeighbours = 0,
      currentPage: current,
      setCurrentPage
    } = props;

    this.pageLimit = typeof pageLimit === "number" ? pageLimit : 30;
    this.totalRecords = typeof totalRecords === "number" ? totalRecords : 0;

    // pageNeighbours can be: 0, 1 or 2
    this.pageNeighbours =
      typeof pageNeighbours === "number" ? Math.max(0, Math.min(pageNeighbours, 2)) : 0;

    this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);

    this.state = { currentPage: current, totalPages: this.totalPages, totalRecords: totalRecords };
  }

  gotoPage = (page) => {
    const currentPage = Math.max(0, Math.min(page, this.state.totalPages));

    const paginationData = {
      currentPage,
      totalPages: this.state.totalPages,
      pageLimit: this.pageLimit,
      totalRecords: this.totalRecords
    };

    this.props.setCurrentPage(currentPage);
  };

  handleClick = (page) => (evt) => {
    evt.preventDefault();
    this.gotoPage(page);
  };

  handleMoveLeft = (evt) => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage - 1);
  };

  handleMoveRight = (evt) => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage + 1);
  };

  fetchPageNumbers = () => {
    const totalPages = this.state.totalPages;
    const currentPage = this.state.currentPage;
    const pageNeighbours = this.pageNeighbours;

    const totalNumbers = this.pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

      let pages = range(startPage, endPage);

      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  static getDerivedStateFromProps(props, state) {
    if(props.currentPage !== state.currentPage){
        //Change in props
        return{
            ...state,
            currentPage: props.currentPage
        };
    }

    if(props.totalRecords !== state.totalRecords){
      //Change in props
      return{
          ...state,
          totalRecords: props.totalRecords,
          totalPages: Math.ceil(props.totalRecords / props.pageLimit),
      };
  }
    return null; // No change to state
}

  render() {

    if (!this.totalRecords || this.state.totalPages === 1) return null;

    const { currentPage } = this.state;
    const pages = this.fetchPageNumbers();

    return (
      <Fragment>
        <Pagination
          className="pagination justify-content-end mb-0"
          listClassName="justify-content-end mb-0"
        >
          {pages.map((page, index) => {
            if (page === LEFT_PAGE)
              return (
                <PaginationItem>
                  <PaginationLink
                    onClick={this.handleMoveLeft}
                    tabIndex="-1"
                    style={{
                      backgroundColor: "#ededed"
                    }}
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
              );

            if (page === RIGHT_PAGE)
              return (
                <PaginationItem>
                  <PaginationLink
                    onClick={this.handleMoveRight}
                    style={{
                      backgroundColor: "#ededed"
                    }}
                  >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              );

            return (
              <PaginationItem key={index} className={page === currentPage ? "active" : ""}>
                <PaginationLink onClick={this.handleClick(page)}>{page}</PaginationLink>
              </PaginationItem>
            );
          })}
        </Pagination>
      </Fragment>
    );
  }
}

NewPagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number,
  pageNeighbours: PropTypes.number
};

export default NewPagination;