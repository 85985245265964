/*eslint-disable*/
import React from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Media,
  Table,
  Container,
  Row,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import Header from 'components/Headers/Header.jsx';
import Search from 'components/Search/Search';
import Varified from '../assets/img/theme/verified.png';
import NotVarified from '../assets/img/theme/verify.png';
import useViewport from 'hooks/useViewport';
import { useHistory } from 'react-router-dom';

import { selectUserToken } from 'redux/user/selectors';
import { selectUser } from 'redux/user/selectors';
import { setUserSearch } from 'redux/user/actions';
import '../assets/css/reportedUsers.css';
import TooltipIcon from 'components/TooltipIcon/TooltipIcon';
import { fetctAllUsersThunkAction } from 'redux/user/actions';
import { activateInactiveUserThunkAction } from 'redux/user/actions';
import toast from 'react-hot-toast';
import SelectSearch from 'components/SelectSearch/SelectSearch';
import ExportButton from 'components/ExportButton/ExportButton';
import Loader from 'components/Loader/Loader';
import NewPagination from 'components/NewPagination/NewPagination';

const AllUsers = () => {
  const [users, setUsers] = React.useState([]);
  const [userTotalCount, setUserTotalCount] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [searchFilter, setSearchFilter] = React.useState(null);
  const [exportedData, setExportedData] = React.useState([]);

  const { width } = useViewport();
  let history = useHistory();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage] = React.useState(10);

  const dispatch = useDispatch();

  const { isLoading, search, allUsers } = useSelector(selectUser);
  const token = useSelector(selectUserToken);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const onSearchHandler = (keyword) => {
    setCurrentPage(1);
    dispatch(setUserSearch(keyword));
  };

  const onFilterHandler = (searchItem) => {
    setCurrentPage(1);
    setSearchFilter(searchItem);
  };

  React.useEffect(() => {
    if (token) {
      dispatch(
        fetctAllUsersThunkAction(
          search,
          searchFilter?.value,
          onSuccess,
          onError
        )
      );
    }
  }, [token, search, searchFilter]);

  React.useEffect(() => {
    const pageUsers = allUsers.slice(
      (currentPage - 1) * postsPerPage,
      currentPage * postsPerPage
    );
    setUsers(pageUsers);
    setUserTotalCount(allUsers.length);
  }, [allUsers, allUsers.length, currentPage]);

  const onUpdateError = (error) => {
    toast.error(error);
  };

  const onUpdateSuccess = () => {
    dispatch(
      fetctAllUsersThunkAction(search, searchFilter?.value, onSuccess, onError)
    );
  };

  const userStatusChangeHandler = async (user, statusLabel) => {
    await dispatch(
      await activateInactiveUserThunkAction(
        user?.id,
        {
          is_influencer: statusLabel,
        },
        onUpdateSuccess,
        onUpdateError
      )
    );
  };

  React.useEffect(() => {
    const formatedData = allUsers.map((item) => ({
      userid: item?.id,
      username: item.username,
      coins: item?.coins,
      email: item.email,
      privates: item.is_private ? 'Yes' : 'No',
      Influencer: item.is_influencer ? 'Yes' : 'No',
    }));

    const csvData = formatedData.reduce((acc, item) => {
      const { userid, username, coins, email, privates, Influencer } = item;
      acc.push(
        [userid, username, coins, email, privates, Influencer].join(',')
      );
      return acc;
    }, []);

    setExportedData(csvData);
  }, [allUsers]);

  const navigateToUserDetailsPage = (id) => {
    history.push(`/admin/user/${id}/details`);
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow" style={{ minHeight: '50vh' }}>
              <CardHeader
                className={
                  width < 768
                    ? 'border-0'
                    : 'border-0 d-flex justify-content-between'
                }
              >
                <div>
                  <h3 className="mb-2">Users</h3>
                  {allUsers.length > 0 && (
                    <ExportButton
                      fileData={exportedData}
                      fileName="users"
                      headers={[
                        'UserId,UserName,Coins,Email,Private,Influencer',
                      ]}
                    >
                      Export Data
                    </ExportButton>
                  )}
                </div>

                <div
                  style={{
                    display: 'flex',
                    // flexDirection: "column",
                    alignItems: 'center',
                    gap: '0.5rem',
                  }}
                >
                  <SelectSearch
                    handleChange={onFilterHandler}
                    placeHolder="Filter user"
                    options={[
                      {
                        label: 'Influencer',
                        value: true,
                        id: 1,
                      },
                      {
                        label: 'Non-Influencer',
                        value: false,
                        id: 2,
                      },
                    ]}
                    value={searchFilter}
                  />

                  <Search onSubmitHandler={onSearchHandler} search={search} />
                </div>
              </CardHeader>
              {isLoading ? (
                <>
                  <>
                    <div className="user_error_loading">
                      <Loader />
                    </div>
                  </>
                </>
              ) : (
                <>
                  {error ? (
                    <>
                      <>
                        <div className="user_error_loading">{error}</div>
                      </>
                    </>
                  ) : (
                    <>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">User Id</th>
                            <th scope="col">Profile</th>
                            <th scope="col">Username</th>
                            <th scope="col">Coins</th>
                            <th scope="col">Email</th>
                            <th scope="col">Private</th>
                            <th scope="col">Influencer</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users.length > 0 &&
                            users.map((user) => {
                              return (
                                <tr key={user?.id}>
                                  <th
                                    scope="row"
                                    onClick={() =>
                                      navigateToUserDetailsPage(user?.id)
                                    }
                                  >
                                    <Media className="align-items-center cursor-pointer">
                                      <Media>
                                        <span className="mb-0 text-sm">
                                          {user?.id}
                                        </span>
                                      </Media>
                                    </Media>
                                  </th>

                                  <td
                                    onClick={() =>
                                      navigateToUserDetailsPage(user?.id)
                                    }
                                  >
                                    <Media className="align-items-center cursor-pointer">
                                      <div className="avatar rounded-circle mr-3">
                                        <img
                                          alt="..."
                                          src={
                                            user?.profile_image ||
                                            require('../assets/img/theme/user-logo.png')
                                              .default
                                          }
                                        />
                                      </div>
                                      <Media>
                                        <span className="mb-0 text-sm">
                                          {user?.reported_by_user?.username}
                                        </span>
                                      </Media>
                                    </Media>
                                  </td>

                                  <td
                                    className="cursor-pointer"
                                    onClick={() =>
                                      navigateToUserDetailsPage(user?.id)
                                    }
                                  >
                                    {user?.username}
                                  </td>

                                  <td
                                    className="cursor-pointer"
                                    onClick={() =>
                                      navigateToUserDetailsPage(user?.id)
                                    }
                                  >
                                    {user?.coins}
                                  </td>

                                  <td
                                    className="cursor-pointer"
                                    onClick={() =>
                                      navigateToUserDetailsPage(user?.id)
                                    }
                                  >
                                    {user?.email}
                                  </td>

                                  <td
                                    className="cursor-pointer"
                                    onClick={() =>
                                      navigateToUserDetailsPage(user?.id)
                                    }
                                  >
                                    {/* {user?.is_private ? 'Yes' : 'No'} */}
                                    <span
                                      style={
                                        user?.is_private
                                          ? {
                                              backgroundColor: '#e5f2e5',
                                              padding: '5px 10px',
                                              color: 'green',
                                              fontWeight: '600',
                                              borderRadius: '5px',
                                            }
                                          : {
                                              backgroundColor: '#ffe5e5',
                                              color: 'red',
                                              fontWeight: '600',
                                              padding: '5px 10px',
                                              borderRadius: '5px',
                                            }
                                      }
                                    >
                                      {user?.is_private ? 'Yes' : 'No'}
                                    </span>
                                  </td>

                                  <td>
                                    {user?.is_influencer ? (
                                      <TooltipIcon
                                        target={'isInfluencer' + user?.id}
                                        label="Remove as an Influencer"
                                        disabled={!user?.status}
                                        iconClass=""
                                        image={Varified}
                                        onclickHandler={() =>
                                          userStatusChangeHandler(user, false)
                                        }
                                        style={{}}
                                      />
                                    ) : (
                                      <TooltipIcon
                                        target={'isInfluencer' + user?.id}
                                        label="Make user as an Influencer"
                                        disabled={!user?.status}
                                        iconClass=""
                                        image={NotVarified}
                                        onclickHandler={() =>
                                          userStatusChangeHandler(user, true)
                                        }
                                        style={{
                                          padding: '5px 7px',
                                        }}
                                      />
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                      {!error && users.length === 0 && (
                        <>
                          <div className="user_error_loading">No users.</div>
                        </>
                      )}
                      {users && users.length > 0 && (
                        <CardFooter className="py-4">
                          <nav aria-label="...">
                            <NewPagination
                              totalRecords={userTotalCount}
                              setCurrentPage={setCurrentPage}
                              pageLimit={postsPerPage}
                              currentPage={currentPage}
                              pageNeighbours={1}
                            />
                          </nav>
                        </CardFooter>
                      )}
                    </>
                  )}
                </>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AllUsers;
