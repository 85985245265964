import React from 'react';
import { Button, Modal, Row, Col } from 'reactstrap';

import '../../assets/css/post-modal.css';

const UserModal = ({ toggleModal, isOpen, user }) => {
  return (
    <>
      <Row>
        <Col md="12">
          <Modal
            backdrop="static"
            className="modal-dialog-centered modal-xl"
            isOpen={isOpen}
            toggle={() => toggleModal(!isOpen)}
          >
            <div className="modal-header">
              <h2 className="modal-title " id="modal-title-default">
                User Details
              </h2>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleModal(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body post-modal-body m-4">
              <div>
                <h3>Reason: </h3>
                <p>{user?.report_text}</p>
              </div>
              <div style={{ marginTop: '2rem' }}>
                <h3>Reported By: </h3>
                <p>
                  <b>Username: </b>
                  {user?.reported_by_user.username}
                  { user?.reported_by_user?.is_influencer === 1 ?
                    <img style={{ width: 20, height: 20, marginLeft: 5 }}
                      alt="verified"
                      src={
                        require('../../assets/img/theme/verified.png')
                          .default
                      }
                    /> : ''
                  }
                </p>
                <p>
                  <b>Full Name: </b>
                  {user?.reported_by_user.full_name}
                </p>
              </div>
              <div style={{ marginTop: '2rem' }}>
                <h3>Reported User: </h3>
                <p>
                  <b>Username: </b> {user?.reported_user?.username}
                  { user?.reported_user?.is_influencer === 1 ?
                    <img style={{ width: 20, height: 20, marginLeft: 5 }}
                      alt="verified"
                      src={
                        require('../../assets/img/theme/verified.png')
                          .default
                      }
                    /> : ''
                  }
                </p>
                <p>
                  <b>Full Name: </b> {user?.reported_user?.full_name}
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                className="ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleModal(false)}
              >
                Close
              </Button>
            </div>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default UserModal;
