/*eslint-disable*/
import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Media,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import Header from "components/Headers/Header.jsx";
// import CommentModal from 'components/Modals/CommentModal';

import useViewport from "hooks/useViewport";

import { selectComments } from "redux/comment/selectors";
import { selectUserToken } from "redux/user/selectors";
import { fetchReportedCommentsThunkAction } from "redux/comment/actions";
import { deleteReportedCommentsThunkAction } from "redux/comment/actions";
import { clearReportedCommentsThunkAction } from "redux/comment/actions";
import TooltipIcon from "components/TooltipIcon/TooltipIcon";
import ExportButton from "components/ExportButton/ExportButton";
import Loader from "components/Loader/Loader";
import { formattedString } from "utils/getMediaUrl";
import NewPagination from "components/NewPagination/NewPagination";

const ReportedComments = () => {
  const [comments, setComments] = React.useState([]);
  const [error, setError] = React.useState(null);

  const { width } = useViewport();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage] = React.useState(10);
  const [exportedData, setExportedData] = React.useState([]);

  const dispatch = useDispatch();

  const { reportedComments, isLoading } = useSelector(selectComments);
  const token = useSelector(selectUserToken);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const onDelete = () => {
    setError(null);
  };

  React.useEffect(() => {
    if (token) {
      dispatch(fetchReportedCommentsThunkAction(onSuccess, onError));
    }
  }, [token]);

  React.useEffect(() => {
    const pageComments = reportedComments.slice(
      (currentPage - 1) * postsPerPage,
      currentPage * postsPerPage
    );
    setComments(pageComments);
  }, [reportedComments, reportedComments.length, currentPage]);

  const deleteCommentHandler = async (commentId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(await deleteReportedCommentsThunkAction(commentId, onDelete, onError));
      }
    });
  };

  const clearCommentHandler = async (commentId, reportedId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#d33",
      confirmButtonText: "Remove",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(await clearReportedCommentsThunkAction(commentId, reportedId, onDelete, onError));
      }
    });
  };
  React.useEffect(() => {
    const formatedData = reportedComments.map((item) => ({
      postid: item?.id,
      description: formattedString(item?.comment_text),
      createdBy: item?.comment_by?.username,
      reportedBy: item?.report_by?.username,
      reason: formattedString(item?.report_text),
    }));

    const csvData = formatedData.reduce((acc, item) => {
      const { postid, description, createdBy, reportedBy, reason } = item;
      acc.push([postid, description, createdBy, reportedBy, reason].join(","));
      return acc;
    }, []);

    setExportedData(csvData);
  }, [reportedComments]);

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow" style={{ minHeight: "50vh" }}>
              <CardHeader
                className={width < 768 ? "border-0" : "border-0 d-flex justify-content-between"}
              >
                <div>
                  <h3 className="mb-2">Reported Comments</h3>
                  {reportedComments?.length > 0 && (
                    <ExportButton
                      fileData={exportedData}
                      fileName="reported-comments"
                      headers={["Comment Id, Comment,Comment By,Reported By,Reason"]}
                    >
                      Export Data
                    </ExportButton>
                  )}
                </div>

                {/* <Search onSubmitHandler={onSearchHandler} search={search} /> */}
              </CardHeader>
              {isLoading ? (
                <>
                  <>
                    <div className="user_error_loading">
                      <Loader />
                    </div>
                  </>
                </>
              ) : (
                <>
                  {error ? (
                    <>
                      <>
                        <div className="user_error_loading">{error}</div>
                      </>
                    </>
                  ) : (
                    <>
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Comment Id</th>
                            <th scope="col">Comment</th>
                            <th scope="col">Comment By</th>
                            <th scope="col">Reported By</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          {comments.length > 0 &&
                            comments.map((comment) => {
                              return (
                                <tr key={comment.id}>
                                  <th scope="row">
                                    <span className="mb-0 text-sm">{comment.id}</span>
                                  </th>
                                  <td title={comment?.comment_text}>
                                    {comment?.comment_text > 50
                                      ? comment?.comment_text.slice(0, 50)
                                      : comment?.comment_text}
                                    {comment?.comment_text > 50 ? "..." : ""}
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        verticalAlign: "middle",
                                        textAlign: "center",
                                      }}
                                      className="avatar rounded-circle mr-3"
                                    >
                                      <img
                                        alt="..."
                                        src={
                                          comment?.comment_by?.profile_image ||
                                          require("../assets/img/theme/user-logo.png").default
                                        }
                                      />
                                    </div>
                                    <span className="mb-0 text-sm">
                                      {comment?.comment_by?.username}
                                    </span>
                                    {comment?.comment_by?.is_influencer === 1 ? (
                                      <img
                                        style={{
                                          width: 20,
                                          height: 20,
                                          marginLeft: 5,
                                        }}
                                        alt="verified"
                                        src={require("../assets/img/theme/verified.png").default}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        verticalAlign: "middle",
                                        textAlign: "center",
                                      }}
                                      className="avatar rounded-circle mr-3"
                                    >
                                      <img
                                        alt="..."
                                        src={
                                          comment?.report_by?.profile_image ||
                                          require("../assets/img/theme/user-logo.png").default
                                        }
                                      />
                                    </div>
                                    <span className="mb-0 text-sm">
                                      {comment?.report_by?.username}
                                    </span>
                                    {comment?.report_by?.is_influencer === 1 ? (
                                      <img
                                        style={{
                                          width: 20,
                                          height: 20,
                                          marginLeft: 5,
                                        }}
                                        alt="verified"
                                        src={require("../assets/img/theme/verified.png").default}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <TooltipIcon
                                      target={"deletecomment" + comment?.id}
                                      label="Delete"
                                      iconClass="fas fa-trash"
                                      onclickHandler={() => deleteCommentHandler(comment?.id)}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    />

                                    <TooltipIcon
                                      target={"clearcomment" + comment?.id}
                                      label="Clear"
                                      iconClass="fas fa-times"
                                      onclickHandler={() =>
                                        clearCommentHandler(comment?.id, comment?.reported_id)
                                      }
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: "1rem",
                                        fontSize: "0.9rem",
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                      {!error && comments?.length === 0 && (
                        <>
                          <div className="user_error_loading">No reported comments.</div>
                        </>
                      )}
                      {comments && comments?.length > 0 && (
                        <CardFooter className="py-4">
                          <nav aria-label="...">
                            <NewPagination
                              totalRecords={reportedComments.length}
                              setCurrentPage={setCurrentPage}
                              pageLimit={postsPerPage}
                              currentPage={currentPage}
                              pageNeighbours={1}
                            />
                          </nav>
                        </CardFooter>
                      )}
                    </>
                  )}
                </>
              )}
            </Card>
          </div>
        </Row>
        {/* {comment && open && (
          <CommentModal toggleModal={toggleModal} isOpen={open} comment={comment} />
        )} */}
      </Container>
    </>
  );
};

export default ReportedComments;
