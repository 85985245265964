import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  Container,
  Row,
} from 'reactstrap';

import Header from 'components/Headers/Header';
import { selectUserToken } from 'redux/user/selectors';

const Error = ({ error, message }) => {
  const token = useSelector(selectUserToken) || localStorage.getItem('token');

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">{error}</h3>
              </CardHeader>
              <CardBody style={{ height: '100%' }}>
                <CardText>{message}</CardText>

                {token ? (
                  <NavLink to="/admin/reportedposts" exact>
                    Go back to home page
                  </NavLink>
                ) : (
                  <NavLink to="/auth/login" exact>
                    Go back to Login page
                  </NavLink>
                )}
              </CardBody>
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Error;
