import {
  DELETE_REPORTED_USER,
  FETCH_ALL_USERS_FAILURE,
  FETCH_ALL_USERS_REQUEST,
  FETCH_ALL_USERS_SUCCESS,
  FETCH_REPORTED_USERS_FAILURE,
  FETCH_REPORTED_USERS_REQUEST,
  FETCH_REPORTED_USERS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  FETCH_USER_DETAILS_REQUEST,
  FETCH_USER_DETAILS_SUCCESS,
  REFRESH_STATE,
  RESET_FORGET_PASSWORD_USER_EMAIL,
  RESET_IS_SIGNING,
  SET_FORGET_PASSWORD_USER_EMAIL,
  SET_IS_SIGNING,
  SET_LOGGEDIN_USER,
  SET_LOGGEDOUT_USER,
  SET_USER_SEARCH,
} from './types';

const initialState = {
  loggedInUser: null,
  token: null,
  isLoading: false,
  error: '',
  isSigning: false,
  reportedUsers: [],
  forgetEmail: '',
  search: '',
  allUsers: [],
  selectedUser: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REPORTED_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case FETCH_REPORTED_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reportedUsers: action.payload,
        error: '',
      };
    case FETCH_REPORTED_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        reportedUsers: [],
        error: action.payload,
      };
    case DELETE_REPORTED_USER:
      return {
        ...state,
        reportedUsers: action.payload,
      };

    case FETCH_ALL_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case FETCH_ALL_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allUsers: action.payload,
        error: '',
      };
    case FETCH_ALL_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        allUsers: [],
        error: action.payload,
      };

    case FETCH_USER_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedUser: action.payload,
        error: '',
      };
    case FETCH_USER_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SET_LOGGEDIN_USER:
      return {
        ...state,
        loggedInUser: action.payload.user,
        token: action.payload.token,
        error: '',
        isSigning: false,
      };
    case SET_LOGGEDOUT_USER:
      return {
        ...state,
        users: [],
        error: '',
        loggedInUser: null,
        token: null,
        forgetEmail: '',
        search: '',
      };
    case SET_FORGET_PASSWORD_USER_EMAIL:
      return {
        ...state,
        forgetEmail: action.payload,
      };
    case RESET_FORGET_PASSWORD_USER_EMAIL:
      return {
        ...state,
        forgetEmail: '',
      };
    case SET_USER_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case SET_IS_SIGNING:
      return {
        ...state,
        isSigning: true,
      };
    case RESET_IS_SIGNING:
      return {
        ...state,
        isSigning: false,
      };
    case REFRESH_STATE:
      return {
        ...state,
        token: action.payload.token,
        loggedInUser: action.payload.user,
      };
    default:
      return state;
  }
};

export default reducer;
