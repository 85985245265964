import React from 'react';
import moment from 'moment';
import { Button, Modal, Row, Col, Media } from 'reactstrap';

import '../../assets/css/post-modal.css';
import { getPostMediaURL } from 'utils/getMediaUrl';

const PostModal = ({ toggleModal, isOpen, post }) => {
  const { media_url1, media_url2 } = getPostMediaURL(post);
  return (
    <>
      <Row>
        <Col md="12">
          <Modal
            backdrop="static"
            className="modal-dialog-centered modal-xl"
            isOpen={isOpen}
            toggle={() => toggleModal(!isOpen)}
          >
            <div className="modal-header">
              <h2 className="modal-title " id="modal-title-default">
                Post Details
              </h2>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleModal(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body post-modal-body m-4">
              <Row>
                <Col lg={6} xl={6}>
                  <Media className="align-items-center position-relative media-body">
                    {typeof media_url1 === 'string' ? (
                      <img
                        alt="..."
                        className="post-image"
                        src={`${media_url1}`}
                      />
                    ) : (
                      <div
                        className="post-image"
                        style={{
                          background: media_url1.background || '',
                          fontSize: '1.3rem',
                          textDecoration: 'none',
                          color: media_url1?.text_color || 'black',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: '1rem',
                        }}
                      >
                        {
                          media_url1?.fullText
                        }
                      </div>
                    )}
                    <div className="h3 vote1">
                      {post?.post_items[0].vote_count} vote
                    </div>
                  </Media>
                </Col>
                <Col lg={6} xl={6}>
                  <Media className="align-items-center position-relative">
                    {typeof media_url2 === 'string' ? (
                      <img
                        alt="..."
                        className="post-image"
                        src={`${media_url2}`}
                      />
                    ) : (
                      <div
                        className="post-image"
                        style={{
                          background: media_url2.background || '',
                          fontSize: '1.3rem',
                          textDecoration: 'none',
                          color: media_url1?.text_color || 'black',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: '1rem',
                        }}
                      >
                        {media_url2?.fullText}
                      </div>
                    )}
                    <div className="h3 vote2">
                      {post?.post_items[1].vote_count} vote
                    </div>
                  </Media>
                </Col>
              </Row>
              <div style={{ marginTop: '2rem' }}>
                <h3>Description </h3>
                <p>{post?.description}</p>
              </div>
              <div style={{ marginTop: '2rem' }}>
                <h3>Reported By: </h3>
                <p>
                  <b>Username: </b>
                  {post?.reported_by_user.username}
                  { post?.reported_by_user?.is_influencer === 1 ?
                    <img style={{ width: 20, height: 20, marginLeft: 5 }}
                      alt="verified"
                      src={
                        require('../../assets/img/theme/verified.png')
                          .default
                      }
                    /> : ''
                  }
                </p>
              </div>
              <div style={{ marginTop: '2rem' }}>
                <h3>Reason: </h3>
                <p>{post?.report_text}</p>
              </div>
              <div style={{ marginTop: '2rem' }}>
                <h3>Created By: </h3>
                <p>
                  <b>Username: </b>
                  {post?.created_by_user.username}
                  { post?.created_by_user?.is_influencer === 1 ?
                    <img style={{ width: 20, height: 20, marginLeft: 5 }}
                      alt="verified"
                      src={
                        require('../../assets/img/theme/verified.png')
                          .default
                      }
                    /> : ''
                  }
                </p>
                <p>
                  <b>Created On: </b>
                  {moment(post?.created_at).format('dddd, Do MMMM, YYYY, ')}
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                className="ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleModal(false)}
              >
                Close
              </Button>
            </div>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default PostModal;
