import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  NavLink,
} from 'reactstrap';

import { verifyOptThunkAction } from 'redux/user/actions';
import { selectUser } from 'redux/user/selectors';

const VerifyOpt = () => {
  const dispatch = useDispatch();

  const state = useSelector(selectUser);

  const history = useHistory();

  const [otp, setOtp] = useState('');

  const navigateToHome = () => {
    history.replace('/auth/resetpassword');
  };

  const submitHadler = (e) => {
    if (otp === '') {
      toast.error('Please enter otp.');
      return;
    }
    e.preventDefault();
    dispatch(
      verifyOptThunkAction(
        { code: otp, email: state.forgetEmail },
        navigateToHome
      )
    );
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form" onSubmit={submitHadler}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText></InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Enter OTP"
                    type="text"
                    required
                    name="otp"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
              </div>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Verify OTP
                </Button>
                <NavLink to="/auth/login" tag={Link}>
                  <small className="nav-link-inner--text">Sign in?</small>
                </NavLink>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default VerifyOpt;
