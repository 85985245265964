/*eslint-disable*/
import React from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Media,
  Table,
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';

import Header from 'components/Headers/Header.jsx';
import useViewport from 'hooks/useViewport';
import clearGridIcon from '../assets/img/brand/clear_grid_icon.svg';

import { selectUserToken } from 'redux/user/selectors';
import '../assets/css/reportedUsers.css';
import { fetchOrderHistoryThunkAction } from 'redux/orders/actions';
import { selectOrderHistory } from 'redux/orders/selectors';
import NewPagination from 'components/NewPagination/NewPagination';
import ExportButton from 'components/ExportButton/ExportButton';

const OrderHistory = () => {
  const [orderData, setOrderData] = React.useState([]);
  const [userTotalCount, setUserTotalCount] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [startDate, setStartDate] = React.useState([]);
  const [endDate, setEndDate] = React.useState([]);
  const [isRendered, setIsRendered] = React.useState(false);
  const [exportedData, setExportedData] = React.useState([]);

  const startDateRef = React.useRef(null);
  const endDateRef = React.useRef(null);

  const { width } = useViewport();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage] = React.useState(10);

  const dispatch = useDispatch();

  const { isLoading, orders } = useSelector(selectOrderHistory);
  const token = useSelector(selectUserToken);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const fetchOrderDataHandler = () => {
    let requestParams = {};

    if (startDate.length && endDate.length) {
      requestParams.startDate = moment(startDate[0]).startOf('day').format();
      requestParams.endDate = moment(endDate[0]).endOf('day').format();
    }

    dispatch(fetchOrderHistoryThunkAction(requestParams, onSuccess, onError));
  };

  React.useEffect(() => {
    if (token) {
      fetchOrderDataHandler();
    }
  }, [token]);

  React.useEffect(() => {
    const pageUsers = orders.slice(
      (currentPage - 1) * postsPerPage,
      currentPage * postsPerPage
    );
    setOrderData(pageUsers);
    setUserTotalCount(orders.length);
  }, [orders, orders.length, currentPage]);

  const updateAfterSearchHandler = () => {
    fetchOrderDataHandler();
  };

  React.useEffect(() => {
    const formatedData = orders.map((item) => ({
      orderId: item?.id,
      username: item?.username,
      coins: item?.coins,
      amount: item?.amount,
      planId: item?.plan_id,
      transactionId: item?.transaction_id,
      purchasedAt: moment(item?.created_at).format('MM/DD/YYYY [at] h:mm A'),
    }));

    const csvData = formatedData.reduce((acc, item) => {
      const {
        orderId,
        username,
        coins,
        amount,
        planId,
        transactionId,
        purchasedAt,
      } = item;
      acc.push(
        [
          orderId,
          username,
          coins,
          amount,
          planId,
          transactionId,
          purchasedAt,
        ].join(',')
      );
      return acc;
    }, []);

    setExportedData(csvData);
  }, [orders]);

  React.useEffect(() => {
    if (startDate && endDate) {
      if (moment(startDate[0]).diff(moment(endDate[0])) > 0) {
        setEndDate([]);
      }
    }
    if (startDate.length === 0) {
      endDateRef.current.flatpickr.clear();
      setEndDate([]);

      if (!isRendered) {
        setIsRendered(true);
      } else {
        setCurrentPage(1);
        updateAfterSearchHandler();
      }
    }
  }, [startDate]);

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow" style={{ minHeight: '50vh' }}>
              <CardHeader
                className={
                  width < 768
                    ? 'border-0'
                    : 'border-0 d-flex justify-content-between'
                }
              >
                <Row className="w-100 align-items-end">
                  <Col sm={12} md="12" lg="3" className="mb-2">
                    <h3 className="mb-2">Order History</h3>
                    {orders?.length > 0 && (
                      <ExportButton
                        fileData={exportedData}
                        fileName="order-history"
                        headers={[
                          'Id,Username,Coins,Amount,Plan Id,Transaction Id,Purchased At',
                        ]}
                      >
                        Export Data
                      </ExportButton>
                    )}
                  </Col>

                  <Col xs="12" md="12" lg="9" className="px-0 mt-3">
                    <div className="d-flex date_filter_container">
                      <div className="d-flex align-items-center position-relative ml-2">
                        <div className="position-relative d-flex align-items-center">
                          <Flatpickr
                            ref={startDateRef}
                            required={true}
                            value={startDate}
                            options={{
                              dateFormat: 'm/d/Y',
                              disableMobile: true,
                              allowInput: false,
                              onClose: (selectedDates) => {
                                setStartDate(selectedDates);
                              },
                            }}
                            onChange={(e) => {
                              setStartDate(e);
                            }}
                            className="form-control ml-2"
                            placeholder="Start Date"
                          />
                          <img
                            src={clearGridIcon}
                            alt="clear grid"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!startDateRef?.current?.flatpickr) return;
                              startDateRef.current.flatpickr.clear();
                            }}
                            className="position-absolute"
                            style={{
                              right: '1rem',
                              cursor: 'pointer',
                            }}
                          />
                        </div>
                        <div className="position-relative d-flex align-items-center">
                          <Flatpickr
                            ref={endDateRef}
                            required={true}
                            value={endDate}
                            options={{
                              dateFormat: 'm/d/Y',
                              minDate: startDate ? new Date(startDate) : '',
                              disableMobile: true,
                              allowInput: false,
                              onClose: (selectedDates) => {
                                setEndDate(selectedDates);
                              },
                            }}
                            onChange={(e) => {
                              setEndDate(e);
                            }}
                            className=" form-control ml-2 "
                            placeholder="End Date"
                          />
                          <img
                            src={clearGridIcon}
                            alt="clear grid"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!endDateRef?.current?.flatpickr) return;
                              endDateRef.current.flatpickr.clear();
                            }}
                            className="position-absolute  "
                            style={{
                              right: '1rem',
                              cursor: 'pointer',
                            }}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center position-relative py-2">
                        <Button
                          onClick={() => {
                            setCurrentPage(1);
                            updateAfterSearchHandler();
                          }}
                          disabled={!startDate?.length || !endDate?.length}
                          style={{
                            backgroundColor: 'dimgray',
                            color: '#fff',
                            marginLeft: '1rem',
                            borderRadius: '7px',
                          }}
                        >
                          Search
                        </Button>
                        <Button
                          outline
                          color="warning"
                          style={{
                            marginRight: '1rem',
                          }}
                          onClick={() => {
                            setCurrentPage(1);
                            setEndDate('');
                            setStartDate('');
                          }}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              {isLoading ? (
                <>
                  <>
                    <div className="user_error_loading">Loading...</div>
                  </>
                </>
              ) : (
                <>
                  {error ? (
                    <>
                      <>
                        <div className="user_error_loading">{error}</div>
                      </>
                    </>
                  ) : (
                    <>
                      <Table
                        className="align-items-center table-flush"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Id</th>
                            <th scope="col">Username</th>
                            <th scope="col">Coins</th>
                            <th scope="col">amount</th>
                            <th scope="col">Plan Id</th>
                            <th scope="col">Transaction Id</th>
                            <th scope="col">Purchased At</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderData.length > 0 &&
                            orderData.map((order) => {
                              return (
                                <tr key={order?.id}>
                                  <th scope="row">
                                    <Media className="align-items-center">
                                      <Media>
                                        <span className="mb-0 text-sm">
                                          {order?.id}
                                        </span>
                                      </Media>
                                    </Media>
                                  </th>

                                  <td scope="col">{order?.username}</td>
                                  <td scope="col">{order?.coins}</td>
                                  <td scope="col">{order?.amount}</td>
                                  <td scope="col">{order?.plan_id}</td>
                                  <td scope="col">{order?.transaction_id}</td>
                                  <td scope="col">
                                    {moment(order?.created_at).format(
                                      'MM/DD/YYYY [at] h:mm A'
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                      {!error && orderData.length === 0 && (
                        <>
                          <div className="user_error_loading">
                            No orders found.
                          </div>
                        </>
                      )}
                      {orderData && orderData.length > 0 && (
                        <CardFooter className="py-4">
                          <nav aria-label="...">
                            <NewPagination
                              totalRecords={userTotalCount}
                              setCurrentPage={setCurrentPage}
                              pageLimit={postsPerPage}
                              currentPage={currentPage}
                              pageNeighbours={1}
                            />
                          </nav>
                        </CardFooter>
                      )}
                    </>
                  )}
                </>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default OrderHistory;
