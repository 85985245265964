/*eslint-disable*/
import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Media,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import Header from "components/Headers/Header.jsx";
import Search from "components/Search/Search";
import UserModal from "components/Modals/UserModal";

import useViewport from "hooks/useViewport";

import { selectUserToken } from "redux/user/selectors";
import { selectUser } from "redux/user/selectors";
import { fetchReportedUsersThunkAction } from "redux/user/actions";
import { deleteReportedUsersThunkAction } from "redux/user/actions";
import { setUserSearch } from "redux/user/actions";
import "../assets/css/reportedUsers.css";
import { clearReportedUsersThunkAction } from "redux/user/actions";
import TooltipIcon from "components/TooltipIcon/TooltipIcon";
import ExportButton from "components/ExportButton/ExportButton";
import Loader from "components/Loader/Loader";
import { formattedString } from "utils/getMediaUrl";
import NewPagination from "components/NewPagination/NewPagination";

const ReportedUsers = () => {
  const [users, setUsers] = React.useState([]);
  const [user, setUser] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [exportedData, setExportedData] = React.useState([]);

  const [open, setOpen] = React.useState(false);

  const { width } = useViewport();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage] = React.useState(10);

  const toggleModal = () => {
    setOpen((open) => !open);
  };

  const dispatch = useDispatch();

  const { reportedUsers, isLoading, search } = useSelector(selectUser);
  const token = useSelector(selectUserToken);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const onDelete = () => {
    setError(null);
  };

  const onSearchHandler = (keyword) => {
    setCurrentPage(1);
    dispatch(setUserSearch(keyword));
  };

  React.useEffect(() => {
    if (token) {
      dispatch(fetchReportedUsersThunkAction(search, onSuccess, onError));
    }
  }, [token, search]);

  React.useEffect(() => {
    const pageUsers = reportedUsers.slice(
      (currentPage - 1) * postsPerPage,
      currentPage * postsPerPage
    );
    setUsers(pageUsers);
  }, [reportedUsers, reportedUsers.length, currentPage]);

  const deleteUserHandler = async (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(await deleteReportedUsersThunkAction(search, userId, onDelete, onError));
      }
    });
  };

  const clearUserHandler = async (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#d33",
      confirmButtonText: "Remove",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(await clearReportedUsersThunkAction(search, userId, onDelete, onError));
      }
    });
  };

  React.useEffect(() => {
    const formatedData = reportedUsers.map((item) => ({
      userid: item?.reported_user?.id,
      username: item?.reported_user?.username,
      reportedBy: item?.reported_by_user?.username,
      reason: formattedString(item?.report_text),
    }));

    const csvData = formatedData.reduce((acc, item) => {
      const { userid, username, reportedBy, reason } = item;
      acc.push([userid, username, reportedBy, reason].join(","));
      return acc;
    }, []);

    setExportedData(csvData);
  }, [reportedUsers]);

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow" style={{ minHeight: "50vh" }}>
              <CardHeader
                className={width < 768 ? "border-0" : "border-0 d-flex justify-content-between"}
              >
                <div>
                  <h3 className="mb-2">Reported Users</h3>
                  {reportedUsers.length > 0 && (
                    <ExportButton
                      fileData={exportedData}
                      fileName="reportedUsers"
                      headers={["UserId, UserName,Reported By,Reason"]}
                    >
                      Export Data
                    </ExportButton>
                  )}
                </div>
                <Search onSubmitHandler={onSearchHandler} search={search} />
              </CardHeader>
              {isLoading ? (
                <>
                  <>
                    <div className="user_error_loading">
                      <Loader />
                    </div>
                  </>
                </>
              ) : (
                <>
                  {error ? (
                    <>
                      <>
                        <div className="user_error_loading">{error}</div>
                      </>
                    </>
                  ) : (
                    <>
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">User Id</th>
                            <th scope="col">Username</th>
                            <th scope="col">Reported By</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          {users.length > 0 &&
                            users.map((user) => {
                              return (
                                <tr key={user?.reported_user?.id}>
                                  <th scope="row">
                                    <Media
                                      className="align-items-center"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setUser(user);
                                        setOpen(true);
                                      }}
                                    >
                                      <Media>
                                        <span className="mb-0 text-sm">
                                          {user?.reported_user?.id}
                                        </span>
                                      </Media>
                                    </Media>
                                  </th>
                                  <td>
                                    <Media
                                      className="align-items-center"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setUser(user);
                                        setOpen(true);
                                      }}
                                    >
                                      <div className="avatar rounded-circle mr-3">
                                        <img
                                          alt="..."
                                          src={
                                            user?.reported_user?.profile_image ||
                                            require("../assets/img/theme/user-logo.png").default
                                          }
                                        />
                                      </div>
                                      <Media>
                                        <span className="mb-0 text-sm">
                                          {user?.reported_user?.username}
                                        </span>
                                      </Media>
                                      {user?.reported_user?.is_influencer === 1 ? (
                                        <img
                                          style={{
                                            width: 20,
                                            height: 20,
                                            marginLeft: 5,
                                          }}
                                          alt="verified"
                                          src={require("../assets/img/theme/verified.png").default}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Media>
                                  </td>

                                  <td>
                                    <Media
                                      className="align-items-center"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setUser(user);
                                        setOpen(true);
                                      }}
                                    >
                                      <div className="avatar rounded-circle mr-3">
                                        <img
                                          alt="..."
                                          src={
                                            user?.reported_by_user?.profile_image ||
                                            require("../assets/img/theme/user-logo.png").default
                                          }
                                        />
                                      </div>
                                      <Media>
                                        <span className="mb-0 text-sm">
                                          {user?.reported_by_user?.username}
                                        </span>
                                      </Media>
                                      {user?.reported_by_user?.is_influencer === 1 ? (
                                        <img
                                          style={{
                                            width: 20,
                                            height: 20,
                                            marginLeft: 5,
                                          }}
                                          alt="verified"
                                          src={require("../assets/img/theme/verified.png").default}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Media>
                                  </td>

                                  <td>
                                    <TooltipIcon
                                      target={"deleteuser" + user?.reported_user?.id}
                                      label="Delete"
                                      iconClass="fas fa-trash"
                                      onclickHandler={() =>
                                        deleteUserHandler(user?.reported_user?.id)
                                      }
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    />

                                    <TooltipIcon
                                      target={"clearuser" + user?.reported_user?.id}
                                      label="Clear"
                                      iconClass="fas fa-times"
                                      onclickHandler={() =>
                                        clearUserHandler(user?.reported_user?.id)
                                      }
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: "1rem",
                                        fontSize: "0.9rem",
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                      {!error && users.length === 0 && (
                        <>
                          <div className="user_error_loading">No any reported Users.</div>
                        </>
                      )}
                      {users && users.length > 0 && (
                        <CardFooter className="py-4">
                          <nav aria-label="...">
                          
                            <NewPagination
                              totalRecords={reportedUsers.length}
                              setCurrentPage={setCurrentPage}
                              pageLimit={postsPerPage}
                              currentPage={currentPage}
                              pageNeighbours={1}
                              
                            />
                          </nav>
                        </CardFooter>
                      )}
                    </>
                  )}
                </>
              )}
            </Card>
          </div>
        </Row>
        {user && open && <UserModal toggleModal={toggleModal} isOpen={open} user={user} />}
      </Container>
    </>
  );
};

export default ReportedUsers;
