import {
  FETCH_REPORTED_POSTS_FAILURE,
  FETCH_REPORTED_POSTS_REQUEST,
  FETCH_REPORTED_POSTS_SUCCESS,
  RESET_POSTS,
  SET_POST_SEARCH,
} from './types';

const initialState = {
  isLoading: false,
  error: '',
  reportedPosts: [],
  search: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REPORTED_POSTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_REPORTED_POSTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reportedPosts: action.payload,
        error: '',
      };
    case FETCH_REPORTED_POSTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        reportedUsers: [],
        error: action.payload,
      };
    case SET_POST_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case RESET_POSTS:
      return {
        ...state,
        isLoading: false,
        reportedPosts: [],
        error: '',
        search: '',
      };
    default:
      return state;
  }
};

export default reducer;
