import React from 'react';
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

const Search = ({ onSubmitHandler, search }) => {
  const [keyword, setKeyword] = React.useState(search || '');

  return (
    <>
      <Form
        role="form"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmitHandler(keyword);
        }}
      >
        <FormGroup className="d-flex mb-0 border rounded">
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-zoom-split-in" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Search"
              style={{height: '38px'}}
              type="text"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </InputGroup>
        </FormGroup>
      </Form>
    </>
  );
};

export default Search;
