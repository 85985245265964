import {
  DELETE_REPORTED_COMMENT,
  FETCH_REPORTED_COMMENTS_FAILURE,
  FETCH_REPORTED_COMMENTS_REQUEST,
  FETCH_REPORTED_COMMENTS_SUCCESS
} from './types';

import {
  clearReportedCommentApi,
  deleteReportedCommentApi,
  getReportedCommentsApi,
} from '../../services/CommentServices';
import toast from 'react-hot-toast';

export const fetchReportedCommentsRequest = () => {
  return {
    type: FETCH_REPORTED_COMMENTS_REQUEST,
  };
};

export const fetchReportedCommentsSuccess = (comments) => {
  return {
    type: FETCH_REPORTED_COMMENTS_SUCCESS,
    payload: comments,
  };
};

export const fetchReportedCommentsFailure = (error) => {
  return {
    type: FETCH_REPORTED_COMMENTS_FAILURE,
    payload: error,
  };
};

export const deleteReportedComment = (comment) => {
  return {
    type: DELETE_REPORTED_COMMENT,
    payload: comment,
  };
};

export const fetchReportedCommentsThunkAction = (onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchReportedCommentsRequest());
      const { data } = await getReportedCommentsApi();

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchReportedCommentsSuccess(data.data.reportedComments));
      onSuccess();
    } catch (error) {
      dispatch(fetchReportedCommentsFailure(error.message));
      onError(error.message);
    }
  };
};

export const deleteReportedCommentsThunkAction = async (
  id,
  onDelete,
  onError
) => {
  return async (dispatch) => {
    try {
      const promise = deleteReportedCommentApi(id);

      toast.promise(promise, {
        loading: 'Loading...',
        success: 'Comment deleted successfully.',
      });

      const response = await promise;

      if (response.data.success !== true) {
        throw new Error(response.data.message);
      }

      dispatch(fetchReportedCommentsRequest());
      const { data } = await getReportedCommentsApi();
      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchReportedCommentsSuccess(data.data.reportedComments));

      onDelete();
    } catch (error) {
      dispatch(fetchReportedCommentsFailure(error.message));
      onError(error.message);
    }
  };
};

export const clearReportedCommentsThunkAction = async (
  id,
  reportedId,
  onDelete,
  onError
) => {
  return async (dispatch) => {
    try {
      const promise = clearReportedCommentApi(id, reportedId);

      toast.promise(promise, {
        loading: 'Loading...',
        success: 'Comment Removed successfully.',
      });

      const response = await promise;

      if (response.data.success !== true) {
        throw new Error(response.data.message);
      }

      dispatch(fetchReportedCommentsRequest());
      const { data } = await getReportedCommentsApi();
      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchReportedCommentsSuccess(data.data.reportedComments));

      onDelete();
    } catch (error) {
      dispatch(fetchReportedCommentsFailure(error.message));
      onError(error.message);
    }
  };
};

