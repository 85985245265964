import {
  DELETE_REPORTED_COMMENT,
  FETCH_REPORTED_COMMENTS_FAILURE,
  FETCH_REPORTED_COMMENTS_REQUEST,
  FETCH_REPORTED_COMMENTS_SUCCESS,
} from './types';

const initialState = {
  isLoading: false,
  error: '',
  reportedComments: [],
  search: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REPORTED_COMMENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_REPORTED_COMMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reportedComments: action.payload,
        error: '',
      };
    case FETCH_REPORTED_COMMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        reportedComments: [],
        error: action.payload,
      };
    case DELETE_REPORTED_COMMENT:
      return {
        ...state,
        reportedComments: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
