export const getPostMediaURL = (post) => {
  let media_url1 = post?.post_items[0]?.media_url?.includes('background')
    ? JSON.parse(post?.post_items[0]?.media_url)
    : post?.post_items[0]?.media_url;

  let media_url2 = post?.post_items[1]?.media_url?.includes('background')
    ? JSON.parse(post?.post_items[1]?.media_url)
    : post?.post_items[1]?.media_url;

  if (typeof media_url1 !== 'string') {
    media_url1 = {
      ...media_url1,
      text:
        media_url1?.text?.length > 3
          ? media_url1?.text?.slice(0, 3) + '...'
          : media_url1?.text,
      fullText: media_url1?.text,
      text_color: '#' + media_url1?.text_color?.split(')')[0]?.split('0xff')[1],
      background: `linear-gradient(to bottom, #${
        media_url1?.background[0]?.split(')')[0]?.split('0xff')[1]
      }, #${media_url1?.background[1]?.split(')')[0]?.split('0xff')[1]})`,
    };
  }

  if (typeof media_url2 !== 'string') {
    media_url2 = {
      ...media_url2,
      text:
        media_url2?.text?.length > 3
          ? media_url2?.text?.slice(0, 3) + '...'
          : media_url2?.text,
      fullText: media_url2?.text,
      text_color: '#' + media_url2?.text_color?.split(')')[0]?.split('0xff')[1],
      background: `linear-gradient(to bottom, #${
        media_url2?.background[0]?.split(')')[0]?.split('0xff')[1]
      }, #${media_url2?.background[1]?.split(')')[0]?.split('0xff')[1]})`,
    };
  }

  return {
    media_url1,
    media_url2,
  };
};

export const formattedString = (oldString) => {
  return oldString?.split('\n')?.join(' ')?.split(',')?.join('|');
};
