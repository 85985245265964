import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';

function TooltipIcon(props) {
  const { target, onclickHandler, iconClass, label, style, image = '' } = props;
  return (
    <>
      <UncontrolledTooltip delay={0} placement="top" target={target}>
        {label}
      </UncontrolledTooltip>
      <Button
        id={target}
        onClick={onclickHandler}
        style={{
          background: 'transparent',
          boxShadow: 'none',
          border: 'none',
          transition: 'none',
          padding: '0',
        }}
      >
        {iconClass ? (
          <i className={iconClass} style={style}></i>
        ) : (
          <img
            src={image}
            alt="icon"
            style={{
              width: '2rem',
              height: '2rem',
            }}
          />
        )}
      </Button>
    </>
  );
}

export default TooltipIcon;
